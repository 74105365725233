<template>
    <input
        :value="modelValue"
        @input="$emit('update:modelValue', ($event.target.value === '' ? null : parseInt($event.target.value)))"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        type="number"
        :step="step"
        class="form-control"
        :class="{[optional_class]: true}"
    >
</template>

<script>
export default {
    name: 'FormInputNumber',
    props: {
        modelValue: {
            type: Number
        },
        required: {
            type: Boolean,
            default: false
        },
        step:{
            type: Number,
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        },
        optional_class: {
            type: String,
            default: ''
        },
    },
    emits: [
        'update:modelValue'
    ],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
